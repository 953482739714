<template>
  <q-page padding>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> Deeplink </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Deeplink" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <GeneratorDeeplink />
  </q-page>
</template>

<script>
import GeneratorDeeplink from '../../components/deeplink/GeneratorDeeplink';

export default {
  components: { GeneratorDeeplink },
  name: 'Deeplink'
};
</script>

<style scoped></style>
