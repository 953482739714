<template>
  <q-card flat class="justify-center">
    <q-form @submit="onDeeplink">
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <select-request
            :v-model="channel"
            outlined
            :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${user.affiliate_id}`"
            option-label="name"
            option-value="id"
            :label="$t('select_channel')"
            clearable
            :rules="[isRequired]"
            @update="(value) => (channel = value === null ? null : value.id)"
          />
        </div>
        <div class="col-6">
          <q-select
            v-model="campaignSelected"
            :options="campaignsFilter"
            :label="campaigns.length > 0 ? $t('select_campaign') : $t('no_campaigns_authorized')"
            :disable="disable"
            outlined
            option-label="campaign_name"
            option-value="campaign_id"
            clear-icon="mdi-close"
            clearable
            :rules="[isRequired]"
            @filter="filterCampaigns"
            use-input
          />
        </div>
        <div class="col-12">
          <q-input
            outlined
            type="url"
            :hint="$t('url_to_deeplink')"
            v-model="urlForDeeplink"
            :label="$t('enter_the_url')"
            :rules="[isRequired]"
          >
          </q-input>
        </div>
        <div class="col-12 text-right">
          <q-btn color="positive" :label="$t('generate_deeplink')" no-caps unelevated type="submit" class="q-px-md" />
        </div>
      </q-card-section>
    </q-form>
    <div class="col-12" v-if="deeplinkUrl">
      <q-input outlined readonly type="text" :value="deeplinkUrl" label="URL Deeplink">
        <template v-slot:append>
          <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(deeplinkUrl)">
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar URL </q-tooltip>
          </q-btn>
        </template>
      </q-input>
    </div>
  </q-card>
</template>

<script>
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';
import advertiserService from '../../services/AdvertiserService';
import Deeplink from '../../services/Deeplink';

export default {
  components: { SelectRequest },
  mixins: [advertiserService, Deeplink],
  name: 'GeneratorDeeplink',
  data() {
    return {
      channel: null,
      channelID: null,
      disable: true,
      campaigns: [],
      campaignsFilter: [],
      campaignSelected: null,
      urlForDeeplink: null,
      deeplinkUrl: null
    };
  },
  watch: {
    channel() {
      this.disable = true;
      this.campaigns = [];
      this.campaignsFilter = [];
      this.campaignSelected = null;
      this.getCampaignRelated(this.channel);
    }
  },
  methods: {
    // copyData(value) {
    //   this.copyToClipboard(value)
    //     .then(() => {
    //       this.successNotify("Copiado!");
    //     })
    //     .catch(() => {
    //       this.errorNotify("Erro ao copiar");
    //     });
    // },
    filterCampaigns(val, update) {
      if (val === '') {
        update(() => {
          this.campaignsFilter = this.campaigns;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.campaignsFilter = this.campaigns.filter((v) => v.campaign_name.toLowerCase().indexOf(needle) > -1);
      });
    },

    async getCampaignRelated() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listSiteCampaignsDeeplinkEnabled(this.channel);
        if (status === 200) {
          this.disable = data.length === 0;
          console.log(data);
          this.campaigns = data;
          this.campaignsFilter = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onDeeplink() {
      this.onLoading(true);
      try {
        const { data, status } = await this.createShortLink(
          this.channel,
          this.campaignSelected.campaign_id,
          this.urlForDeeplink
        );
        if (status === 200) {
          this.deeplinkUrl = data.deeplink;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style scoped></style>
