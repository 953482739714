const serviceApp = process.env.VUE_APP_DEEPLINK_SERVICE;

export default {
  methods: {
    async getDeeplink(deeplinkId) {
      return await this.$http.get(`/v2/redirect?app=${serviceApp}&path=/api/get/deeplink/info/${deeplinkId}`);
    },

    async createShortLink(siteId, campaignId, url) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/v3/shortlink',
        params: {
          data: {
            id_site: `${siteId}`,
            id_campaign: `${campaignId}`,
            url: `${url}`
          }
        }
      });
    },

    async editDeeplink(authorizedDomains, campaignId, parameters) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/edit/deeplink',
        params: {
          data: {
            authorized_domains: `${authorizedDomains}`,
            id_campaign: `${campaignId}`,
            parameters: `${parameters}`
          }
        }
      });
    },

    async createDeeplinkBatch(data) {
      return await this.$http.post(`/v2/redirect`, {
        app: serviceApp,
        path: '/api/v3/loteShortlink',
        params: { data }
      });
    }
  }
};
